<script>
import axiosClient from "@/axios";
import toast from "@/views/components/Toast.vue";


export default {
    data() {
        return {
            loading: false,
            media: {}
        };
    },
    mounted() {
        this.getMedia();
    },
    methods: {
        getMedia() {
            axiosClient.get('media/category/'+this.$route.query.id+'/files').then(({ data }) => {
                this.media = data.data;
            });
        },
        add_media(){
            this.$router.push({ path: "/add-media", query: { category: this.$route.query.id } });
        },
        delMedia(file) {
            if(confirm("Confirming you want to delete media?")){
                this.loading = true;
                axiosClient.get('media/category/files/'+file+'/delete').then(() => {
                    this.loading = false;
                    this.getMedia();
                }).catch(({ data }) => {
                    this.loading = false;
                    toast.error(data);
                });
            }
        },
        
    },
};
</script>
<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <h6>Media files</h6>
                    </div>
                    <div class="card-body p-3">
                        <div class="d-flex justify-content-end ms-3">
                            <a @click="add_media()" class="btn btn-sm btn-primary">Add Media</a>
                        </div>
                        <div class="table-responsive p-0">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-uppercase text-secondary text-sm font-weight-bolder opacity-7">
                                            Title
                                        </th>
                                        <th
                                            class="text-start text-uppercase text-secondary text-sm font-weight-bolder opacity-7">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="mb-3" v-for="(file, index) in media" :key="index">
                                        <td>
                                            <div class="d-flex px-2 py-1">

                                                <div class="d-flex flex-column justify-content-center">
                                                    <h6 class="mb-0 text-md">{{ file.title }}</h6>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="align-middle">
                                            <!-- <a class="text-white btn btn-sm btn-primary font-weight-bold text-xs"
                                                @click="view_media(file.id)">View media</a> -->
                                            <a class="text-white btn btn-sm btn-danger font-weight-bold text-xs ms-2"
                                                @click="delMedia(file.id)">Delete</a>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

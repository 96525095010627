<script>
import axiosClient from "@/axios";
import ArButton from "@/components/ArgonButton.vue";
import toast from "@/views/components/Toast.vue";

export default {
    inject: ["view"],
    data() {
        return {
            dispnewticketing: false,
            editevent: "",
            loading: false,
            media: {},
        };
    },
    components: {
        ArButton,
    },
    methods: {
        image(event) {
            this.media.image = event.target.files[0];
        },
        addMedia() {
            console.log(this.media);

            this.loading = true;
            axiosClient.post('media/category/'+this.$route.query.category+'/files/add', this.media, {
                headers: {
                    "content-type": "multipart/form-data",
                }
            }).then(() => {
                this.loading = false;
                this.$router.push('articles');
            }).catch(({ data }) => {
                this.loading = false;
                toast.error(data);
            });
        },
    },
};
</script>
<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-5">
                <div class="card">
                    <div class="card-header pb-0">
                        <h6>Add Media</h6>
                        <small class="text-danger">Insert links to files only</small>
                    </div>
                    <div class="card-body p-3">
                        <form method="POST" @submit.prevent="addMedia" enctype="multipart/form-data">
                            <div class="form-group">
                                <h6>Image</h6>
                                <input type="text" class="form-control" v-model="media.image">
                            </div>
                            <div class="form-group">
                                <h6>Title</h6>
                                <input type="text" class="form-control" v-model="media.title" required>
                            </div>
                            <div class="form-group">
                                <h6>File</h6>
                                <input type="text" class="form-control" v-model="media.file" required>
                            </div>
                            <ar-button type="submit" color="success" active="false"
                                :loading="loading">Upload</ar-button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<style>
.select2-selection {
    width: 13rem;
}

.select2-dropdown {
    width: 13rem !important;
}

.ck-editor__editable[role="textbox"] {
    /* editing area */
    min-height: 200px;
    max-width: 64rem;
    border-width: 3px solid;
}
</style>

<script>
import axiosClient from "@/axios";

export default {
 data() {
   return {
     loading: false,
     chats:{}
   };
 },
 mounted() {
   this.getChats();
 },
 methods: {
    getChats() {
    axiosClient.get('posts').then(({ data }) => {
        this.posts = data.data;
    });
}
 },
};
</script>
<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header pb-0">
                      <h6>Chats</h6>
                    </div>
                    <div class="card-body p-3">
                        
                      <div class="table-responsive p-0">
                        <table class="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th
                                class="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                              >
                                User
                              </th>
                              <th
                                class="text-start text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="mb-3" v-for="(chat, index) in chats" :key="index">
                              <td>
                                <div class="d-flex px-2 py-1">

                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="mb-0 text-md">{{chat.title}}</h6>
                                    <p class="text-md text-secondary mb-0">
                                      {{chat.created_at}}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td class="align-middle">
                                <a
                                  href="javascript:;"
                                  class="text-white btn btn-sm btn-primary font-weight-bold text-xs"
                                  data-toggle="tooltip"
                                  data-original-title="Edit user"
                                  >Edit</a
                                >
                              </td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
            </div>
        </div>

    </div>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import axiosClient from "@/axios";
import store from "@/store";
const body = document.getElementsByTagName("body")[0];
import Carousel from './components/Carousel.vue';


export default {
    data() {
        return {
            loading: false,
            form: {},
            images:['https://res.cloudinary.com/dzh1cgxjd/image/upload/v1716830761/Oracle/Jast%20Mode%20Images-20240517T142111Z-001/Jast%20Mode%20Images/IMG_8584_tftkhf.jpg',
                'https://res.cloudinary.com/dzh1cgxjd/image/upload/v1715955953/Oracle/Jast%20Mode%20Images-20240517T142111Z-001/Jast%20Mode%20Images/el-bernard-image-7_b3ulo1.jpg',
                'https://res.cloudinary.com/dzh1cgxjd/image/upload/v1715955948/Oracle/Jast%20Mode%20Images-20240517T142111Z-001/Jast%20Mode%20Images/el-bernard-image-29_dulkgn.jpg',
                'https://res.cloudinary.com/dzh1cgxjd/image/upload/v1715955940/Oracle/Jast%20Mode%20Images-20240517T142111Z-001/Jast%20Mode%20Images/el-bernard-image-20_vr0fbn.jpg'
            ],
            error:"",
        };
    },
    components: {
        ArgonButton,
        ArgonInput,
        Carousel
    },
    mounted() {
        store.state.hideConfigButton = true;
        store.state.showNavbar = false;
        store.state.showSidenav = false;
        store.state.showFooter = false;
        body.classList.remove("bg-gray-100");
    },
    unmounted() {
        store.state.hideConfigButton = false;
        store.state.showNavbar = true;
        store.state.showSidenav = true;
        store.state.showFooter = true;
        body.classList.add("bg-gray-100");
    },
    methods: {
        login() {
            this.loading = true;
            this.error = '';
            axiosClient.post('login', this.form).then(({ data }) => {
                this.loading = false;
                if(data.data.user.level == 'admin'){
                    sessionStorage.setItem('token', data.data.token);
                    this.$router.push('dashboard');
                }else{
                    this.error = "Access not allowed";
                }
            }).catch(({data}) => {
                this.loading = false;
                console.log(data);
                this.error = data.message;
                // toast.error(data);
            });
        },
    },
};

</script>
<template>
    <!-- <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div> -->
    <main class="mt-0 main-content">
        <section>
            <div class="page-header min-vh-100">
                <div class="container">
                    <div class="row">
                        <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
                            <div class="card card-plain">
                                <div class="pb-0 card-header text-start">
                                    <h4 class="font-weight-bolder">Admin Dashboard</h4>
                                    <p class="mb-0">Enter your email and password to sign in</p>
                                    <p class="mb-0 text-danger">{{error}}</p>
                                </div>
                                <div class="card-body">
                                    <form role="form" @submit.prevent="login">
                                        <div class="mb-3">
                                            <argon-input id="email" type="email" placeholder="Email"
                                                v-model="form.email" size="lg" />
                                        </div>
                                        <div class="mb-3">
                                            <argon-input id="password" type="password" placeholder="Password"
                                                v-model="form.password" size="lg" />
                                        </div>

                                        <div class="text-center">
                                            <argon-button class="mt-4" variant="gradient" color="warning" fullWidth
                                                :loading="loading" size="lg">Sign in</argon-button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div
                            class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column">
                            <carousel :images="images"  style="background-size: cover;" />
                            <span class="mask bg-gradient-dark opacity-6"></span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

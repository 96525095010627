<script setup>
import { computed } from "vue";
import { useRoute,useRouter } from "vue-router";
import { useStore } from "vuex";

import SidenavItem from "./SidenavItem.vue";

const store = useStore();
const isRTL = computed(() => store.state.isRTL);
const router = useRouter();


const getRoute = () => {
    const route = useRoute();
    const routeArr = route.path.split("/");
    return routeArr[1];
};

const logout = () => {
    sessionStorage.clear();
    router.push('/');

};
</script>
<template>
    <div class="collapse navbar-collapse w-auto h-auto h-100" id="sidenav-collapse-main">
        <ul class="navbar-nav">
            <li class="nav-item">
                <sidenav-item to="/dashboard" :class="getRoute() === 'dashboard' ? 'active' : ''"
                    :navText="isRTL ? 'لوحة القيادة' : 'Dashboard'">
                    <template v-slot:icon>
                        <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>

            <li class="nav-item">
                <sidenav-item to="/articles" :class="getRoute() === 'articles' ? 'active' : ''"
                    :navText="isRTL ? 'الجداول' : 'Articles'">
                    <template v-slot:icon>
                        <i class="ni ni-calendar-grid-58 text-warning text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>

            <li class="nav-item">
                <sidenav-item to="/prayer" :class="getRoute() === 'prayer' ? 'active' : ''"
                    :navText="isRTL ? 'الجداول' : 'Today\'s Prayer'">
                    <template v-slot:icon>
                        <i class="fa fa-hands text-warning text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>

            <li class="nav-item">
                <sidenav-item to="/media-categories" :class="getRoute() === 'media-categories' ? 'active' : ''"
                    :navText="isRTL ? 'الجداول' : 'Media Upload'">
                    <template v-slot:icon>
                        <i class="fa fa-film text-warning text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>

            <li class="nav-item">
                <sidenav-item to="/chats" :class="getRoute() === 'chats' ? 'active' : ''" navText="Chats">
                    <template v-slot:icon>
                        <i class="ni ni-credit-card text-success text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>

            <li class="nav-item">
                <sidenav-item to="/send-alert" :class="getRoute() === 'send-alert' ? 'active' : ''"
                    navText="Send notifications">
                    <template v-slot:icon>
                        <i class="ni ni-app text-info text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>
            <li class="nav-item">
                <a @click="logout" class="nav-link btn">Logout</a>
            </li>
        </ul>
    </div>
</template>

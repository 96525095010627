<script>
import axiosClient from "@/axios";

export default {
    inject: ["view"],
    data() {
        return {
            loading: false,
            categories: {}
        };
    },
    mounted() {
        this.getCategories();
    },
    methods: {
        getCategories() {
            axiosClient.get('media/categories').then(({ data }) => {
                this.categories = data.data;
            });
        },
        view_media(id) {
            this.$router.push({ path: "/media-files", query: { id: id } });
        },
    },
};
</script>
<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <h6>Media Categories</h6>
                    </div>
                    <div class="card-body p-3">
                        <div class="d-flex justify-content-end ms-3">

                            <!-- <router-link to="/add-article" class="btn btn-sm btn-primary">Add Article</router-link> -->
                        </div>
                        <div class="table-responsive p-0">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-uppercase text-secondary text-sm font-weight-bolder opacity-7">
                                            Title
                                        </th>
                                        <th
                                            class="text-start text-uppercase text-secondary text-sm font-weight-bolder opacity-7">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="mb-3" v-for="(category, index) in categories" :key="index">
                                        <td>
                                            <div class="d-flex px-2 py-1">

                                                <div class="d-flex flex-column justify-content-center">
                                                    <h6 class="mb-0 text-md">{{ category.category }}</h6>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="align-middle">
                                            <a class="text-white btn btn-sm btn-primary font-weight-bold text-xs"
                                                @click="view_media(category.id)">View media</a>
                                            <a class="text-white btn btn-sm btn-danger font-weight-bold text-xs ms-2"
                                                @click="delete_article(article.id)">Delete</a>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

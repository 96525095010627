<script>
import axiosClient from "@/axios";
import ArButton from "@/components/ArgonButton.vue";
import toast from "@/views/components/Toast.vue";

export default {
    data() {
        return {
            dispnewticketing: false,
            editevent: "",
            loading: false,
            form: {},
        };
    },
    components: {
        ArButton,
    },
    mounted() {
        this.getPrayer();
    },
    methods: {
        image(event) {
            this.post.cover = event.target.files[0];
        },
        getPrayer() {
            axiosClient.get('prayer/today').then(({ data }) => {
                this.form = data.data;
                console.log(data);
            });
        },
        createPost() {
            console.log(this.post);

            this.loading = true;
            axiosClient.post('prayer/update', this.form, {
                headers: {
                    "content-type": "multipart/form-data",
                }
            }).then(({data}) => {
                this.loading = false;
                toast.success(data.message);
            }).catch(({ data }) => {
                this.loading = false;
                toast.error(data);
            });
        },
    },
};
</script>
<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-5">
                <div class="card">
                    <div class="card-header pb-0">
                        <h6>Update Today's prayer</h6>
                    </div>
                    <div class="card-body p-3">
                        <form method="POST" @submit.prevent="createPost" enctype="multipart/form-data">
                            <!-- <div class="form-group">
                                <h6>Image</h6>
                                <input type="file" class="form-control" v-on:change="image">
                            </div> -->
                            <div class="form-group">
                                <h6>Prayer</h6>
                                <textarea class="form-control" v-model="form.prayer" rows="4" cols="10" />
                            </div>

                            <ar-button type="submit" color="success" active="false"
                                :loading="loading">Update</ar-button>
                            <!-- <button class="btn btn-sm btn-primary">Publish</button> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<style>
.select2-selection {
    width: 13rem;
}

.select2-dropdown {
    width: 13rem !important;
}

.ck-editor__editable[role="textbox"] {
    /* editing area */
    min-height: 200px;
    max-width: 64rem;
    border-width: 3px solid;
}
</style>

<script>
import axiosClient from "@/axios";

export default {
    inject: ["view"],
    data() {
        return {
            loading: false,
            posts: {}
        };
    },
    mounted() {
        this.getPosts();
    },
    methods: {
        getPosts() {
            axiosClient.get('posts').then(({ data }) => {
                this.posts = data.data;
            });
        },
        delete_article(id) {
            if (confirm('Confirm you want to delete?')) {
                axiosClient.get('posts/' + id + '/delete', { id: id }).then(({ data }) => {
                    console.log(data);
                    this.getPosts();
                }).catch(({ data }) => {
                    console.log(data);
                });
            }
        },
        edit_article(id) {
            this.$router.push({ path: "/edit-article", query: { id: id } });
        },
    },
};
</script>
<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <h6>Articles</h6>
                    </div>
                    <div class="card-body p-3">
                        <div class="d-flex justify-content-end ms-3">

                            <router-link to="/add-article" class="btn btn-sm btn-primary">Add Article</router-link>
                        </div>
                        <div class="table-responsive p-0">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-uppercase text-secondary text-sm font-weight-bolder opacity-7">
                                            Title
                                        </th>
                                        <th
                                            class="text-start text-uppercase text-secondary text-sm font-weight-bolder opacity-7">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="mb-3" v-for="(article, index) in posts" :key="index">
                                        <td>
                                            <div class="d-flex px-2 py-1">

                                                <div class="d-flex flex-column justify-content-center">
                                                    <h6 class="mb-0 text-md">{{ article.title }}</h6>
                                                    <p class="text-md text-secondary mb-0">
                                                        {{ article.created_at }}
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="align-middle">
                                            <a class="text-white btn btn-sm btn-primary font-weight-bold text-xs"
                                                @click="edit_article(article.id)">Edit</a>
                                            <a class="text-white btn btn-sm btn-danger font-weight-bold text-xs ms-2"
                                                @click="delete_article(article.id)">Delete</a>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

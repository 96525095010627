<script>
import axiosClient from "@/axios";
import ArButton from "@/components/ArgonButton.vue";
import toast from "@/views/components/Toast.vue";

export default {
    inject: ["view"],
    data() {
        return {
            dispnewticketing: false,
            editevent: "",
            loading: false,
            post: {},
        };
    },
    components: {
        ArButton,
    },
    mounted() {
    },
    methods: {
        getPosts() {
            console.log('loading');
            axiosClient.get('posts').then(({ data }) => {
                this.posts = data.data;
                console.log(this.posts);
            });
        },
        createPost() {
            console.log(this.post);

            this.loading = true;
            axiosClient.post('posts/add', this.post, {
                headers: {
                    "content-type": "multipart/form-data",
                }
            }).then(() => {
                this.loading = false;
                this.$router.push('articles');
            }).catch(({ data }) => {
                this.loading = false;
                toast.error(data);
            });
        },
        stats(program) {
            sessionStorage.setItem("event", program);
            this.$router.push("/event");
        },
    },
};
</script>
<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-5">
                <div class="card">
                    <div class="card-header pb-0">
                        <h6>Send alert</h6>
                    </div>
                    <div class="card-body p-3">
                        <form method="POST" @submit.prevent="createPost" enctype="multipart/form-data">
                            <div class="form-group">
                                <h6>Title</h6>
                                <input type="text" class="form-control" v-model="post.title">
                            </div>
                            <div class="form-group">
                                <h6>Message</h6>
                                <textarea class="form-control description">
                                   
                                </textarea>
                            </div>
                            <ar-button type="submit" color="success" active="false"
                                :loading="loading">Send</ar-button>
                            <!-- <button class="btn btn-sm btn-primary">Publish</button> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<style>
</style>

import { createRouter, createWebHashHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import Tables from "../views/Tables.vue";
import Articles from "../views/Articles.vue";
import AddArticle from "../views/AddArticle.vue";
import Prayer from "../views/Prayer.vue";
import EditArticle from "../views/EditArticle.vue";
import Billing from "../views/Billing.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import Signin from "../views/Signin.vue";
import Chats from "../views/Chats.vue";
import SendAlert from "../views/SendAlert.vue";
import MediaCategory from "../views/media/MediaCategories.vue";
import MediaFiles from "../views/media/MediaFiles.vue";
import AddMedia from "../views/media/AddMedia.vue";


const routes = [
  {
    path: "/",
    name: "/",
    component: Signin,
    meta:{isGuest:true}
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta:{requiresAuth:true}
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
    meta:{requiresAuth:true}

  },
  {
    path: "/articles",
    name: "Articles",
    component: Articles,
    meta:{requiresAuth:true}

  },
  {
    path: "/chats",
    name: "Chats",
    component: Chats,
    meta:{requiresAuth:true}
  },
  {
    path: "/send-alert",
    name: "Send Alert",
    component: SendAlert,
    meta:{requiresAuth:true}
  },
  {
    path: "/add-article",
    name: "Add Article",
    component: AddArticle,
    meta:{requiresAuth:true}
  },
  {
    path: "/prayer",
    name: "Prayer",
    component: Prayer,
    meta:{requiresAuth:true}
  },
  {
    path: "/media-categories",
    name: "Media Category",
    component: MediaCategory,
    meta:{requiresAuth:true}
  },
  {
    path: "/media-files",
    name: "Media Files",
    component: MediaFiles,
    meta:{requiresAuth:true}
  },
  {
    path: "/add-media",
    name: "Add Media",
    component: AddMedia,
    meta:{requiresAuth:true}
  },
  {
    path: "/edit-article",
    name: "Edit Article",
    component: EditArticle,
    meta:{requiresAuth:true}
  },
  {
    path: "/media",
    name: "All Media",
    component: AddArticle,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
    // console.log(store.state.user.token);
    if (to.meta.requiresAuth && !sessionStorage.getItem("token")) {
        router.push('/');
    } else if (to.isGuest && sessionStorage.getItem("token")) {
        if (sessionStorage.getItem("user") == "attendee") {
            router.push('dashboard');
        } else {
            router.push({ name: "Dashboard" });
        }
    } else {
        next();
    }
});

export default router;

// import axios from '@nuxtjs/axios'
import axios from "axios"
import router from "@/router";
// import store from 'store';

//  var host = 'http://127.0.0.1:8000/api/';
 var host = '/api/';
//  var host = 'https://oracle.wowlogbook.com/api/';
const axiosClient = axios.create({
    baseURL: host,
    withCredentials: true,
});

axiosClient.interceptors.request.use((config)=>{
    config.headers.Authorization = 'Bearer '+sessionStorage.getItem('token');
    // config.headers.Content_Type = "application/json";
    config.headers.Accept = "application/json";
    return config;
})

// Add a response interceptor
axiosClient.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function ({response}) {
    if(response.status == 401){
        sessionStorage.clear();
        router.push('/');
      }
      // console.log(response);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(response);
  });


export default axiosClient;
